import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  total: 0,
  form: {},
  dialog: false,
};

const certificatesSlice = createSlice({
  name: "certificates",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      if (payload) {
        state.form = payload;
      } else state.form = initialState.form;
      state.dialog = !state.dialog;
    },
  },
});

export const { setDialog } = certificatesSlice.actions;
export default certificatesSlice.reducer;
