import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  total: 0,
  form: {},
  dialog: false,
};

const educationDegreeSlice = createSlice({
  name: "education_degree",
  initialState,
  reducers: {
    setEducationDegree: (state, action) => {
      state.data = action.payload;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.form = payload;
      } else state.form = initialState.form;
      state.dialog = !state.dialog;
    },
  },
});

export const { setEducationDegree, setDialog } = educationDegreeSlice.actions;
export default educationDegreeSlice.reducer;
