import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const IsRegister = ({ children }) => {
  const isRegister = useSelector((state) => state.user?.is_register);

  return isRegister ? children : <Navigate replace to="/completeRegister" />;
};

export default IsRegister;
