import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const seekerLangaugeSlice = createSlice({
  name: "seekerLanguage",
  initialState,
  reducers: {
    setSeekerLanguage: (state, action) => {
      return [...action.payload];
    },
  },
});

export const { setSeekerLanguage } = seekerLangaugeSlice.actions;
export default seekerLangaugeSlice.reducer;
