import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticated: false,
  email: null,
  id: null,
  is_register: false,
  locale_code: null,
  mobile: null,
  name: null,
  profile_pic: null,
  type: null,
  token: null,
  company_name: null,
  contact_email: null,
  full_address: null,
  social_type: null,
  facebook: null,
  linkedin: null,
  website: null,
  latitude: null,
  longitude: null,
  city: {},
  province: {},
  education: [],
  experiences: [],
  certificates: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    handleLogOut: () => {
      return initialState;
    },
    updateEduStore: (state, { payload }) => {
      if (payload) {
        const index = state.education.findIndex((e) => e.id === payload.id);
        state.education[index] = payload;
      }
    },
    appendEduToStore: (state, { payload }) => {
      if (payload) {
        state.education = [...state.education, payload];
      }
    },
    deleteEdu: (state, { payload }) => {
      if (payload) {
        state.education = state.education.filter((e) => e.id !== payload.id);
      }
    },
    updateCertStore: (state, { payload }) => {
      if (payload) {
        const index = state.certificates.findIndex((c) => c.id === payload.id);
        state.certificates[index] = payload;
      }
    },
    appendCertToStore: (state, { payload }) => {
      if (payload) {
        state.certificates = [...state.certificates, payload];
      }
    },
    deleteCert: (state, { payload }) => {
      if (payload) {
        state.certificates = state.certificates.filter(
          (c) => c.id !== payload.id
        );
      }
    },
    updateExpStore: (state, { payload }) => {
      if (payload) {
        const index = state.experiences.findIndex((e) => e.id === payload.id);
        state.experiences[index] = payload;
      }
    },
    appendExpToStore: (state, { payload }) => {
      if (payload) {
        state.experiences = [...state.experiences, payload];
      }
    },
    deleteExp: (state, { payload }) => {
      if (payload) {
        state.experiences = state.experiences.filter(
          (e) => e.id !== payload.id
        );
      }
    },
  },
});

export const {
  setUser,
  handleLogOut,
  updateEduStore,
  appendEduToStore,
  deleteEdu,
  updateCertStore,
  appendCertToStore,
  deleteCert,
  appendExpToStore,
  updateExpStore,
  deleteExp,
} = userSlice.actions;
export default userSlice.reducer;
