import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const maritalStatusSlice = createSlice({
  name: "marital_status",
  initialState,
  reducers: {
    setMaritalStatus: (state, action) => {
      return [...action.payload];
    },
  },
});

export const { setMaritalStatus } = maritalStatusSlice.actions;
export default maritalStatusSlice.reducer;
