import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const savedJobsSlice = createSlice({
  name: "savedJobs",
  initialState,
  reducers: {
    setSavedJobs: (state, action) => {
      return [...action.payload];
    },
    removeSavedJob: (state, action) => {
      return state.filter((savedJob) => savedJob.job.id !== action.payload);
    },
  },
});

export const { setSavedJobs, removeSavedJob } = savedJobsSlice.actions;
export default savedJobsSlice.reducer;
