import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "./Slidbar";
import { useFetch } from "../hooks";
import { setLanguages } from "../slices/languagesSlice";
import Loader from "./Loader";
import OneSignal from "react-onesignal";

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages);
  const user = useSelector((state) => state.user);
  const [initialized, setInitialized] = useState(false);
  const { refetch } = useFetch(`/${user.type}/language`, {
    enabled: false,
    onSuccess: (res) => {
      const languages = res.data?.data;
      // return only ar, en language
      const filteredLanguage = languages.filter(
        (lang) => lang.code === "en" || lang.code === "ar"
      );

      // set language to redux store
      dispatch(setLanguages(filteredLanguage));
    },
  });

  useEffect(() => {
    if (!languages.length) {
      refetch();
    }
  }, []);

  useEffect(() => {
    const initializeOneSignal = async () => {
      try {
        await OneSignal.init({
          appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
          safari_web_id: process.env.REACT_APP_ONESIGNAL_APP_ID_SAFARI,
          // allowLocalhostAsSecureOrigin: true,
          notifyButton: {
            enable: true,
          },
          // serviceWorkerParam: {
          //   scope: "/OneSignalSDKWorker.js",
          // },
          // serviceWorkerPath: "/OneSignalSDKWorker.js",
          // serviceWorkerUpdaterPath: "/OneSignalSDKUpdaterWorker.js",
        });

        setInitialized(true);
        await OneSignal.login(user.id);
      } catch (error) {
        console.error("OneSignal initialization error:", error);
      }
    };

    if (!initialized && user.id) {
      initializeOneSignal();
    }
  }, [initialized, user.id]);

  return <Slider>{children}</Slider>;
};

export default MainLayout;
