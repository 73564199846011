import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const slidbarSlice = createSlice({
  name: "slidbar",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { setOpen } = slidbarSlice.actions;
export default slidbarSlice.reducer;
