import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobs: {
    data: [],
    total: 0,
    filters: {
      popular: null,
      search: "",
      skip: 0,
      take: 10,
      page: 0,
      education_degree_id: null,
      job_title_id: null,
      city_id: null,
      province_id: null,
    },
  },
  jobDetails: {},
  recommendedJobs: { data: [], total: 0 },
  newestJobs: { data: [], total: 0 },
};

const seekerJobsSlice = createSlice({
  name: "seekerJobs",
  initialState,
  reducers: {
    setRecommendedJobs: (state, { payload }) => {
      if (payload) {
        state.recommendedJobs.data = payload.data;
        state.recommendedJobs.total = payload.total;
      }
    },
    setNewestJobs: (state, { payload }) => {
      if (payload) {
        state.newestJobs.data = payload.data;
        state.newestJobs.total = payload.total;
      }
    },

    clearJobs: (state) => {
      state.jobs = initialState.jobs;
    },

    setJobsFilters: (state, { payload }) => {
      state.jobs.filters = {
        ...state.jobs.filters,
        ...payload,
      };
    },

    refreshAndSetJobs: (state, { payload }) => {
      if (payload) {
        state.jobs.data = payload.data;
        state.jobs.total = payload.total;
      }
    },
    resetJobsFilters: (state) => {
      state.jobs.filters = initialState.jobs.filters;
    },
    mergeJobs: (state, { payload }) => {
      state.jobs.data = [...state.jobs.data, ...payload.data];
      state.jobs.total = payload.total;
    },

    pushJob: (state, action) => {
      return {
        data: [...state.data, action.payload],
        total: state.total + 1,
      };
    },
    setJobDetails: (state, action) => {
      state.jobDetails = action.payload;
    },
  },
});

export const {
  refreshAndSetJobs,
  mergeJobs,
  clearJobs,
  pushJob,
  setJobDetails,
  setRecommendedJobs,
  setNewestJobs,
  setJobsFilters,
  resetJobsFilters,
} = seekerJobsSlice.actions;
export default seekerJobsSlice.reducer;
