import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const cvTemplatesSlice = createSlice({
  name: "cvTemplates",
  initialState,
  reducers: {
    setCVTemplates: (state, action) => {
      return [...action.payload];
    },
  },
});

export const { setCVTemplates } = cvTemplatesSlice.actions;
export default cvTemplatesSlice.reducer;
