import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  form: {},
  dialog: false,
};

const experiencesSlice = createSlice({
  name: "experiences",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      if (payload) {
        state.form = payload;
      } else state.form = initialState.form;
      state.dialog = !state.dialog;
    },
  },
});

export const { setEducationDegree, setDialog } = experiencesSlice.actions;
export default experiencesSlice.reducer;
