import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Translator, useLocalization } from "../lang";

export default function AlertDialog({
  open,
  handleClose,
  title,
  description,
  action,
  ...props
}) {
  const { direction } = useLocalization();
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        dir={direction}
        {...props}
      >
        <DialogTitle id="alert-dialog-title">
          <Translator textKey={title} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Translator textKey={description} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <Translator textKey="cancel" />
          </Button>
          <Button
            onClick={() => {
              action();
              handleClose();
            }}
            autoFocus
          >
            <Translator textKey="agree" />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
