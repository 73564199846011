import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/employSlice";
import provincesReducer from "../slices/provincesSlice";
import citiesReducer from "../slices/citiesSlice";
import languagesReducer from "../slices/languagesSlice";
import jobsReducer from "../slices/jobsSlice";
import jobDataReducer from "../slices/jobDataSlice";
import applicantsReducer from "../slices/applicantsSlice";
import applicantStagesReducer from "../slices/applicantStagesSlice";
import notificationsReducer from "../slices/notificationsSlice";
import formatedCitiesReducer from "../slices/formatedCitiesSlice";
import employerFieldReducer from "../slices/EmployerFieldSlice";
import appReducer from "../slices/appSlice";
import seekerNotificationsReducer from "../slices/seekerWeb/seekerNotifications";
import savedJobsReducer from "../slices/seekerWeb/savedJobs";
import seekerJobsReducer from "../slices/seekerWeb/seekerJobs";
import jobFieldReducer from "../slices/jobFieldSlice";
import maritalStatusReducer from "../slices/maritalStatus";
import seekerLanguageReducer from "../slices/seekerWeb/seekerLanguage";
import educationDegreeReducer from "../slices/seekerWeb/educationDegree";
import certificationsReducer from "../slices/seekerWeb/certificates";
import experiencesReducer from "../slices/seekerWeb/experiences";
import seekerJobTitleReducer from "../slices/seekerWeb/seekerJobTitle";
import bannersReducer from "../slices/seekerWeb/banners";
import cvTemplatesReducer from "../slices/seekerWeb/cvTemplates";
import slidbarReducer from "../slices/slidbarSlice";
const store = configureStore({
  reducer: {
    seekerJobs: seekerJobsReducer,
    savedJobs: savedJobsReducer,
    seekerNotifications: seekerNotificationsReducer,
    app: appReducer,
    user: userReducer,
    provinces: provincesReducer,
    cities: citiesReducer,
    languages: languagesReducer,
    jobs: jobsReducer,
    jobData: jobDataReducer,
    applicants: applicantsReducer,
    applicantStages: applicantStagesReducer,
    notifications: notificationsReducer,
    formatedCities: formatedCitiesReducer,
    employer_field: employerFieldReducer,
    job_field: jobFieldReducer,
    marital_status: maritalStatusReducer,
    seekerLanguage: seekerLanguageReducer,
    education_degree: educationDegreeReducer,
    certificates: certificationsReducer,
    experiences: experiencesReducer,
    seekerJobTitle: seekerJobTitleReducer,
    banners: bannersReducer,
    cvTemplates: cvTemplatesReducer,
    slidbar: slidbarReducer,
  },
});

export default store;
