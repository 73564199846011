import axios from "axios";
import { useState, useCallback } from "react";
import useAxios from "./useAxios";
const actions = ["put", "post", "patch", "delete"];

const useMutate = (url, options = {}) => {
  const {
    initialData = null,
    onSuccess,
    onError,
    action = null,
    headers = {},
  } = options;
  const axios = useAxios();
  const [isMutating, setIsMutating] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState(initialData);

  if (!action) {
    throw new Error("useMutate hook, action is required.");
  }

  if (!actions.includes(action)) {
    throw new Error(
      `useMutate hook, unvalid action ${action}, use only one of ${actions.join(
        ", "
      )}`
    );
  }

  /**
   * use to mutate data.
   */
  const mutate = useCallback(
    (data, { query, headers: requestHeaders } = {}) => {
      setIsMutating(true);

      // Merge default headers with request-specific headers
      const mergedHeaders = { ...headers, ...requestHeaders };

      axios[action](`${url}${query ? `/${query}` : ""}`, data, {
        headers: mergedHeaders,
      })
        .then((response) => {
          setData(response);
          setIsSuccess(true);
          // check if onSuccess function exists, then execute it and pass response to it
          if (onSuccess) {
            onSuccess(response);
          }
        })
        .catch((error) => {
          setIsError(true);
          // check if onError function exists, then execute it and pass error to it
          if (onError) {
            onError(error);
          }
        })
        .finally(() => {
          setIsMutating(false);
        });
    },
    []
  );

  return { mutate, data, isMutating, isError, isSuccess };
};

export default useMutate;
