import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const jobFieldSlice = createSlice({
  name: "job_field",
  initialState,
  reducers: {
    setJobField: (state, action) => {
      return [...action.payload];
    },
  },
});

export const { setJobField } = jobFieldSlice.actions;
export default jobFieldSlice.reducer;
