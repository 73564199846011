import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Translator } from "../lang";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";

const SignInPrompt = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    handleClose();
    navigate("/login");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "10px",
          minWidth: { xs: "90%", sm: "400px" },
          maxWidth: "400px",
          padding: "0",
          background: "rgba(255, 255, 255, 0.9)",
          backdropFilter: "blur(8px)",
          boxShadow: `
            0 2px 4px -1px rgba(0,0,0,0.01),
            0 4px 6px -1px rgba(0,0,0,0.05),
            0 10px 15px -3px rgba(0,0,0,0.05)
          `,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#A32A3C",
          py: 3,
          borderRadius: "10px 10px 0 0",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
          }}
        >
          <LockIcon sx={{ fontSize: 40, mb: 1 }} />
          <DialogTitle
            sx={{
              textAlign: "center",
              color: "inherit",
              p: 0,
              fontSize: "1.5rem",
              fontWeight: 600,
            }}
          >
            <Translator textKey="signInRequired" />
          </DialogTitle>
        </Box>
      </Box>

      <DialogContent sx={{ pt: 3, pb: 2, px: 3 }}>
        <Typography
          align="center"
          sx={{
            color: "#666",
            fontSize: "1rem",
            lineHeight: 1.5,
          }}
        >
          <Translator textKey="signInToAccess" />
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "center",
          gap: 2,
          pb: 3,
          px: 3,
          flexDirection: { xs: "column", sm: "row" },
          "& .MuiButton-root": {
            minWidth: { xs: "200px", sm: "120px" },
          },
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            color: "#A32A3C",
            borderColor: "#A32A3C",
            "&:hover": {
              borderColor: "#A32A3C",
              backgroundColor: "rgba(163, 42, 60, 0.04)",
            },
          }}
        >
          <Translator textKey="cancel" />
        </Button>
        <Button
          onClick={handleSignIn}
          variant="contained"
          sx={{
            backgroundColor: "#A32A3C",
            "&:hover": {
              backgroundColor: "#8B2433",
            },
            marginLeft: { xs: "0 !important", sm: "0" },
          }}
        >
          <Translator textKey="signin" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignInPrompt;
