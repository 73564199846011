export const lang = {
  en: {
    home: "Home",
    profile: "Profile",
    logout: "Logout",
    yes: "Yes",
    deleteAccount: "Delete Account",
    maritalStatus: "Marital Status",
    DeleteAccountFormDescription:
      "Please enter your email address. An email message will be sent to you to verify the process of removing your account.",
    deleteVerifyDescription: "Are you sure you want to delete your account?",
    companyName: "Company Name",
    fullName: "Full Name",
    address: "Address",
    phoneNumber: "Mobile Number",
    email: "Email",
    province: "Province",
    city: "City",
    facebookLink: "Facebook Link",
    linkedinLink: "LinkedIn Link",
    save: "Save",
    signin: "Sign in",
    password: "Password",
    forgotPassword: "Forgot Password",
    dontHaveAccount: "Don't have an account? Sign Up",
    continueAsGuest: "Continue as Guest",
    invalidEmail: "Invalid email",
    requiredField: "This field is required",
    invalidPassword:
      "invalid password, password must be at least 8 characters long",
    forgotPasswordFormDescription:
      "Enter your email so a code will be sent to you.",
    send: "Send",
    passwordNotMatch: "Passwords not matched",
    completeRegistration: "Complete Registration",
    congratulations: "Congratulations",
    recommendedJobs: "Popular Jobs",
    verifyEmailRedirect:
      "Your email has been successfully verified. You can close this page now, and login to your account.",
    error: "Error",
    backToLogin: "Back To Login",
    jobs: "Jobs",
    job_field: "Job Field",
    jobTitle: "Job Title",
    eduactionDegree: "Eduaction Degree",
    views: "Views",
    appliers: "Appliers",
    operations: "Operations",
    addJob: "Add Job",
    editJob: "Job Details",
    addNewJob: "Add New Job",
    searchJob: "Search Job",
    workType: "Work Type",
    otherJobTitle: "Other Job Title",
    educationDegree: "Education Degree",
    description: "Description",
    fullAddress: "Full Address",
    workDays: "Work Days",
    salary: "Salary",
    dueTo: "Due To",
    gender: "Gender",
    isActive: "Is Active",
    publishEmployerName: "Publish Employer Name",
    add: "Add",
    delete: "Delete",
    addedSuccess: "Added Successed",
    edit: "Edit",
    applicant: "Applicant",
    showDetailes: "Show Detailes",
    showAll: "Show all",
    editSuccess: "Edit Successed",
    deleteSuccess: "Delete Successed",
    agree: "Agree",
    cancel: "Cancel",
    deleteJobAlertTitle: "Delete Job",
    deleteJobAlertDecription: "Are you sure you want to delete this job?",
    applicants: "Applicants",
    name: "Name",
    applicantStage: "Applicant Stage",
    jobInfo: "Job Information",
    jobPublisherInfo: "Job Publisher Information",
    downloadCv: "Download CV",
    uploadCv: "Upload your CV",
    uploadCvFileTypes: "Allowed file types: pdf, doc, jpg, jpeg, png",
    fileTypeNotAllowed: "File type not allowed",
    updating: "Updating",
    about: "About",
    experience: "Experience",
    skills: "Skills",
    certificates: "Certificates",
    education: "Education",
    present: "Present",
    graduationDate: "Graduation Date",
    startDate: "Start",
    endDate: "End",
    seekerInfo: "Seeker Information",
    showSeekerInfo: "Show Seeker Information",
    nearestPoint: "Nearest Point",
    somethingWentWrong: "Something went wrong, please try again",
    contactEmail: "Contact Email",
    signinWithGoogle: "Sign in with Google",
    searchApplicants: "Search Applicants",
    filter: "Filter",
    clear: "Clear",
    clearAll: "Clear All",
    due: "Due",
    changePassword: "Change Password",
    confirmPassword: "Confirm Password",
    changePasswordSuccess: "Password changed successfully",
    selectYourLocation: "Please Select your location",
    noApplicantsYet: "There are no Applicants yet",
    noJobsYet: "There are no Jobs yet",
    salaryFrom: "Minimum Salary",
    salaryTo: "Maximum Salary",
    currency: "Currency",
    notifications: "Notifications",
    noNotification: "There are no notifications yet",
    accepted: "Accepted",
    pendding: "Pendding",
    updateSuccess: "Update Success",
    website: "Website",
    selectResumeTemplate: "Select Resume Template",
    otherJobTitlePlaceholder: "React developer",
    workDaysPlaceholder: "sunday - thursday",
    fullAddressPlaceholder: "14.ramadan street",
    savedJobs: "Saved Jobs",
    jobDescription: "Job Description",
    apply: "Apply",
    contactInfo: "Contact Information",
    remove: "Remove",
    applied: "Applied",
    saveSuccess: "Job saved successfully",
    unsaveSuccess: "Job unsaved sucessfully",
    applySuccess: "Applied to the job sucessfully",
    birthDate: "Birth date",
    drivingLicense: "Driving License",
    languages: "Languages",
    studyField: "Field of Study",
    uniName: " University Name",
    grade: "Grade",
    certificateResource: "Issued by",
    expireDate: "Expire date",
    experiences: "Experiences",
    duties: "Duties",
    newestJobs: "Newest Jobs",
    generateCV: "Create Resume",
    passport: "Passport",
    seeker: "Seeker",
    employer: "Employer",
    unsave: "Unsave",
    jobSaved: "This job is already saved",
    generateCVDesc:
      "Choose the template that you want to use and your resume will be generated automatically based on your information (optional)",
    pageNotFound: "404 - Page Not Found",
    pageNotFoundDescription:
      "The page you are looking for doesn't exist or has been moved",
    backToHome: "Back to Home",
    totalSavedJobs: "Saved Jobs",
    savedJobsDescription: "Track all your bookmarked job opportunities",
    loginToApply: "Login to Apply",
    signInToAccess: "Please sign in to access this page",
    notSpecified: "Not Specified",
    male: "Male",
    female: "Female",
    both: "Both",
  },
  ar: {
    home: "الصفحة الرئيسية",
    generateCV: "انشاء سيرة ذاتية",
    generateCVDesc:
      "اختر النموذج الذي تريد استخدامه وسيتم انشاء السيرة الذاتية بشكل تلقائي بناءً على معلوماتك (اختياري)",
    profile: "معلومات الحساب",
    logout: "تسجيل الخروج",
    yes: "نعم",
    deleteAccount: "حذف الحساب",
    DeleteAccountFormDescription:
      "الرجاء ادخال بريدك الالكتروني. سيتم ارسال رسالة الى بريدك الالكتروني لتاكيد حذف حسابك.",
    deleteVerifyDescription: "هل انت متأكد من حذف حسابك؟",
    companyName: "اسم الشركة",
    fullName: "اسم المستخدم",
    address: "العنوان",
    phoneNumber: "رقم الهاتف",
    email: "البريد الالكتروني",
    province: "المحافظة",
    city: "المدينة",
    facebookLink: "facebook رابط حساب",
    linkedinLink: "linkedin رابط حساب",
    save: "حفظ",
    signin: "تسجيل الدخول",
    password: "كلمة المرور",
    forgotPassword: "هل نسيت كلمة المرور",
    dontHaveAccount: "لا تمتلك حساب؟ انشاء حساب",
    continueAsGuest: "الدخول كمستخدم ضيف",
    invalidEmail: "البريد الالكتروني غير صالح",
    requiredField: "هذاالحقل مطلوب",
    invalidPassword: "كلمة المرور غير صالحة, يجب ان يكون 8 رمز او اكثر",
    forgotPasswordFormDescription:
      "أدخل بريدك الإلكتروني حتى يتم إرسال الرمز إليك.",
    send: "ارسال",
    passwordNotMatch: "كلمة المرور غير متطابقة",
    completeRegistration: "اكمال عملية انشاء الحساب",
    congratulations: "تهانينا",
    verifyEmailRedirect:
      "تم التحقق من بريدك الإلكتروني بنجاح. يمكنك الآن إغلاق هذه الصفحة وتسجيل الدخول إلى حسابك.",
    error: "حدث خطأ ما",
    backToLogin: "العودة إلى صفحة تسجيل الدخول",
    jobs: "الوظائف",
    jobTitle: "عنوان الوظيفة",
    eduactionDegree: "درجة التعليم",
    views: "المشاهدات",
    appliers: "تم التقديم",
    operations: "العمليات",
    addJob: "اضافة وظيفة",
    editJob: "تفاصيل الوظيفة",
    addNewJob: "اضافة وظيفة جديدة",
    searchJob: "بحث عن وظيفة",
    workType: "نوع العمل",
    otherJobTitle: "عنوان اضافي للوظيفة",
    educationDegree: "التحصيل الدراسي",
    description: "الوصف",
    fullAddress: "العنوان الكامل",
    workDays: "ايام العمل",
    salary: "الراتب",
    dueTo: "صالحة لغاية",
    gender: "الجنس",
    showAll: "عرض الكل",
    isActive: "نشر",
    publishEmployerName: "اضهار اسم الموظف",
    add: "اضافة",
    delete: "حذف",
    addedSuccess: "تمت الاضافة بنجاح",
    edit: "تعديل",
    applicant: "المتقدمين",
    showDetailes: "عرض التفاصيل",
    editSuccess: "تم التحديث بنجاح",
    deleteSuccess: "تم الحذف بنجاح",
    agree: "موافق",
    cancel: "الغاء",
    deleteJobAlertTitle: "حذف وظيفة",
    deleteJobAlertDecription: "هل انت متأكد من حذف هذه الوظيفة؟",
    applicants: "المتقدمين",
    name: "الاسم",
    applicantStage: "حالة التقديم",
    jobInfo: "معلومات الوظيفة",
    jobPublisherInfo: "معلومات الناشر",
    downloadCv: "تحميل السيرة الذاتية",
    updating: "تحديث",
    about: "نبذة عامة",
    experience: "الخبرة",
    certificates: "الشهادات",
    education: "التعليم",
    skills: "المهارات",
    present: "مستمر",
    graduationDate: "تاريخ التخرج",
    startDate: "تاريخ اليدء",
    endDate: "تاريخ الانتهاء",
    seekerInfo: "معلومات المتقدم",
    showSeekerInfo: "عرض معلومات المتقدم",
    recommendedJobs: "الوضائف الشائعة",
    newestJobs: "احدث الوظائف",
    nearestPoint: "اقرب نقطة دالة",
    somethingWentWrong: "حصل خطأ ما الرجاء المحاولة فيما بعد",
    contactEmail: "البريد الالكتروني المخصص للتواصل",
    signinWithGoogle: "تسجيل الدخول بواسطة البريد الالكتروني",
    searchApplicants: "بحث عن متقدم",
    filter: "تصفية",
    clear: "الغاءالتصفية",
    due: "صالحة لغاية",
    changePassword: "تغير كلمة المرور",
    confirmPassword: "تأكيد كلمة المرور",
    changePasswordSuccess: "تم تغير كلمة المرور بنجاح",
    selectYourLocation: "يرجى تحديد الموقع الخاص بك",
    noApplicantsYet: "لا يوجد متقدمين حتى الان",
    noJobsYet: "لم يتم اضافة وظيفة حتى الان",
    salaryFrom: "الحد الادنى للمرتب",
    salaryTo: "الحد الاعلى للمرتب",
    currency: "العملة",
    notifications: "ألاشعارات",
    noNotification: "لا توجد اشعارات",
    accepted: "مفعل",
    pendding: "قيد الموافقة",
    clearAll: "مسح الكل",
    updateSuccess: "تم التحديث بنجاح",
    website: "الموقع الالكتروني",
    selectResumeTemplate: "اختيار القالب للسيرة الذاتية",
    otherJobTitlePlaceholder: "مطور React",
    workDaysPlaceholder: "الاحد - الخميس",
    fullAddressPlaceholder: "شارع 14 رمضان",
    savedJobs: "الوظائف المحفوظة",
    jobDescription: "وصف الوظيفة",
    apply: "تقديم",
    contactInfo: "معلومات التواصل",
    remove: "مسح",
    applied: "تم التقديم",
    saveSuccess: "تم حفظ الوظيفة بنجاح",
    unsaveSuccess: "تم حذف الوظيفة بنجاح",
    applySuccess: "تم التقديم على الوظيفة بنجاح",
    birthDate: "تاريخ الميلاد",
    job_field: "المجال الوظيفي",
    maritalStatus: "الحالة الزوجية",
    passport: "جواز سفر",
    jobSaved: "تم حفظ الوظيفة مسبقاً",
    uploadCv: "رفع السيرة الذاتية",
    uploadCvFileTypes: "الملفات المسموح بها: pdf, doc, jpg, jpeg, png",
    fileTypeNotAllowed: "نوع الملف غير مسموح",
    drivingLicense: "اجازة السوق",
    languages: "اللغات",
    studyField: "قسم الدراسة",
    uniName: "اسم الجامعة",
    grade: "الدرجة او المعدل",
    certificateResource: "جهة الاصدار",
    expireDate: "تاريخ الانتهاء",
    experiences: "الخبرات",
    duties: "الواجبات",

    seeker: "باحث عن وظيفة",
    employer: "شركة",
    unsave: "الغاء الحفظ",
    pageNotFound: "404 - صفحة غير موجودة",
    pageNotFoundDescription: "الصفحة التي تبحث عنها غير موجودة أو تم نقلها",
    backToHome: "العودة الى الرئيسية",
    totalSavedJobs: "الوظائف المحفوظة",
    savedJobsDescription: "تتبع جميع فرص العمل المحفوظة لديك",
    loginToApply: "سجل الدخول للتفديم",
    signInToAccess: "يرجى تسجيل الدخول للوصول الى هذه الصفحة",
    notSpecified: "غير محدد",
    male: "ذكر",
    female: "أنثى",
    both: "كلاهما",
  },
};

/**
 * Get language object for code param.
 * @param {string} code code for language, `default is "en"`
 * @returns
 */
export function getLang(code = "en") {
  return lang[code];
}
/**
 * Return translate for word by passing language code and text key fro word.
 * @param {*} code  language code.
 * @param {*} textKey text key for word.
 * @returns
 */
export function getLangTranslate(code, textKey) {
  return lang[code][textKey];
}
