import React, { Suspense } from "react";
import store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Authentication, LanguageSetting, Loader } from "./components";
import { LocalizationProvider, getLang } from "./lang";
import { getBrowserLanguage } from "./utils";
import { GoogleOAuthProvider } from "@react-oauth/google";

const Providers = ({ children }) => {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      nonce="hhjhjhjh"
    >
      <Provider store={store}>
        <Suspense
          fallback={
            <>
              <Loader />
            </>
          }
        >
          <Router>
            <Authentication>
              <LocalizationProvider
                langCode={getBrowserLanguage()}
                defaultLanguage={getLang(getBrowserLanguage())}
              >
                <LanguageSetting>{children}</LanguageSetting>
              </LocalizationProvider>
            </Authentication>
          </Router>
        </Suspense>
      </Provider>
    </GoogleOAuthProvider>
  );
};

export default Providers;
