import { Box, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormState } from "react-hook-form";

const TextInput = ({
  name,
  label,
  control,
  Icon,
  showLabel = true,
  ...props
}) => {
  const { errors } = useFormState({ control, name: name });
  const error = errors[name]?.message;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            width: "100%",
            marginBottom: "1.2em",
          }}
        >
          {Icon && (
            <Icon
              sx={{
                color: "var(--primary-color)",
                marginInlineEnd: 1,
                my: 0.5,
              }}
            />
          )}
          <TextField
            label={showLabel && label}
            variant={props.variant || "standard"}
            sx={{
              width: "100%",
            }}
            error={error ? true : false}
            helperText={error}
            {...props}
            {...field}
          />
        </Box>
      )}
    />
  );
};

export default TextInput;
