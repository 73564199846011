import React, { useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, CircularProgress } from "@mui/material";

const FileInputButton = ({
  sx,
  handleFileChange,
  buttonWidth = "4em",
  buttonHeight = "4em",
  iconWidth = "2rem",
  iconHeight = "2rem",
  loading = false,
}) => {
  const inputRef = useRef(null);

  const handleChange = (e) => {
    handleFileChange(e);
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        width: "fit-content",
        borderRadius: "50%",
        ...sx,
      }}
    >
      <input
        type="file"
        ref={inputRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <button
        onClick={handleButtonClick}
        disabled={loading}
        style={{
          background: "var(--primary-color)",
          hover: "none",
          width: buttonWidth,
          height: buttonHeight,
          borderRadius: "50%",
          outline: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "4px solid #fff",
        }}
      >
        {loading ? (
          <CircularProgress size={20} sx={{ color: "#fff" }} />
        ) : (
          <AddIcon
            sx={{
              color: "#fff",
              width: iconWidth,
              height: iconHeight,
            }}
          />
        )}
      </button>
    </Box>
  );
};

export default FileInputButton;
