import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const seekerNotificationsSlice = createSlice({
  name: "seekerNotifications",
  initialState,
  reducers: {
    setSeekerNotifications: (state, action) => {
      return [...action.payload];
    },
  },
});

export const { setSeekerNotifications } = seekerNotificationsSlice.actions;
export default seekerNotificationsSlice.reducer;
