import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { IsRegister, PrivateRoute } from "./components";
import { lazyImport } from "./utils";
import { useSelector } from "react-redux";

const { Login } = lazyImport(() => import("./pages/Login/index"), "Login");
const { Register } = lazyImport(
  () => import("./pages/Register/index"),
  "Register"
);
const { Home } = lazyImport(() => import("./pages/Home/index"), "Home");
const { ForgotPassword } = lazyImport(
  () => import("./pages/ForgotPassword/index"),
  "ForgotPassword"
);
const { ResetPassword } = lazyImport(
  () => import("./pages/ResetPassword/index"),
  "ResetPassword"
);

const { DeleteAccount } = lazyImport(
  () => import("./pages/deleteAccount/index"),
  "DeleteAccount"
);

const { DeleteVerify } = lazyImport(
  () => import("./pages/deleteAccount/index"),
  "DeleteVerify"
);

const { VerifyEmail } = lazyImport(
  () => import("./pages/VerifyEmail/index"),
  "VerifyEmail"
);

const { Profile } = lazyImport(
  () => import("./pages/Profile/index"),
  "Profile"
);

const { CompleteRegister } = lazyImport(
  () => import("./pages/CompleteRegister/index"),
  "CompleteRegister"
);

const { CompleteSeekerRegister } = lazyImport(
  () => import("./pages/seekerWeb/completeSeekerRegister/index"),
  "CompleteSeekerRegister"
);

const { Jobs } = lazyImport(() => import("./pages/Jobs/index"), "Jobs");
const { AddJob } = lazyImport(() => import("./pages/Jobs/index"), "AddJob");
const { SeekerProfile } = lazyImport(
  () => import("./pages/Seeker/index"),
  "SeekerProfile"
);

const { JobDetailes } = lazyImport(
  () => import("./pages/Jobs/index"),
  "JobDetailes"
);

const { Notifications } = lazyImport(
  () => import("./pages/Notifications/index"),
  "Notifications"
);

const { SeekerHome } = lazyImport(
  () => import("./pages/seekerWeb/home/index"),
  "SeekerHome"
);

const { SeekerNotifications } = lazyImport(
  () => import("./pages/seekerWeb/Notifications/index"),
  "SeekerNotifications"
);

const { SeekerWebProfile } = lazyImport(
  () => import("./pages/seekerWeb/profile/index"),
  "SeekerWebProfile"
);

const { SavedJobs } = lazyImport(
  () => import("./pages/seekerWeb/savedJobs/index"),
  "SavedJobs"
);

const { SeekerJobs } = lazyImport(
  () => import("./pages/seekerWeb/jobs/index"),
  "SeekerJobs"
);

const { SeekerJobDetails } = lazyImport(
  () => import("./pages/seekerWeb/seekerJobDetails/index"),
  "SeekerJobDetails"
);

const { JobApplicant } = lazyImport(
  () => import("./pages/PublicJobApplicant/index"),
  "JobApplicant"
);

const { NotFound } = lazyImport(
  () => import("./pages/NotFound/index"),
  "NotFound"
);

const RouterApp = () => {
  const { type } = useSelector((state) => state.user);

  return (
    <Routes>
      {/* ! Private Routes */}

      {type === "employer" ? (
        <>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <IsRegister>
                  <Navigate to="/profile" />
                </IsRegister>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <IsRegister>
                  <Profile />
                </IsRegister>
              </PrivateRoute>
            }
          />
          <Route
            path="/jobs"
            element={
              <PrivateRoute>
                <IsRegister>
                  <Jobs />
                </IsRegister>
              </PrivateRoute>
            }
          />
          <Route
            path="/jobs/add"
            element={
              <PrivateRoute>
                <IsRegister>
                  <AddJob />
                </IsRegister>
              </PrivateRoute>
            }
          />
          <Route
            path="/jobs/:id"
            element={
              <PrivateRoute>
                <IsRegister>
                  <JobDetailes />
                </IsRegister>
              </PrivateRoute>
            }
          />
          <Route
            path="/completeRegister"
            element={
              <PrivateRoute>
                <CompleteRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/seeker/:id"
            element={
              <PrivateRoute>
                <SeekerProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <PrivateRoute>
                <Notifications />
              </PrivateRoute>
            }
          />
        </>
      ) : (
        <>
          <Route path="/" element={<SeekerHome />} />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <IsRegister>
                  <SeekerWebProfile />
                </IsRegister>
              </PrivateRoute>
            }
          />
          <Route
            path="/completeRegister"
            element={
              <PrivateRoute>
                <CompleteSeekerRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/saved-jobs"
            element={
              <PrivateRoute>
                <IsRegister>
                  <SavedJobs />
                </IsRegister>
              </PrivateRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <PrivateRoute>
                <SeekerNotifications />
              </PrivateRoute>
            }
          />
          <Route path="/jobs" element={<SeekerJobs />} />
          <Route path="/jobs/:id" element={<SeekerJobDetails />} />
        </>
      )}
      {/* ! Public Routes */}
      <>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/deleteAccount" element={<DeleteAccount />} />
        <Route path="/delete-verify" element={<DeleteVerify />} />
        <Route path="/job-applicant/:id" element={<JobApplicant />} />
        <Route path="*" element={<NotFound />} />
      </>
    </Routes>
  );
};

export default RouterApp;
